/***********************************************************************
 components.js contains shared UI components, objects, or packages 
 of each page's application of the MPA
************************************************************************/
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'animate.css';
import '../css/common.css';
import menuIcon from '../images/logos/evp_icon.jpg';
import clientLogo from '../images/logos/client_logo.png';
import loading from '../images/animations/loading.gif';
import _ from '../images/_.png';
import Help from '../images/Help.png';
import example1 from '../images/examples/example1.png';
import example2 from '../images/examples/example2.png';

let domain;

if (process.env.ENVIRONMENT === 'production') {
  // In production, use the environment variable
  domain = process.env.APIDOMAIN; 
} else {
  // In development, load from the local file
  const localEnv = require('../../ENV');
  domain = localEnv.domain;
}

function initApp() { // apply element place holders to the main section of the app element
    $('#app').html(`
        <div id="banner"></div>
        <div id="interface" class="mx-3 mt-2 justify-content-center"></div>
    `);
}

function announcement() {
    const Greeting = 'Hello Everyone,';
    const Opening = 'Happy "Final Submission Eve!" As you know tomorrow, May 8 by 5:00 PM EST you will need to have sent in all your Final Submission items for judging. Please again keep in mind this is a hard deadline; any items received after 5:00 PM will not be counted for credit. The Final Submission checklist can be found in the Project Brief. I have taken some time to put together a "Frequently Asked Questions" list to try and answer some of the questions we get most often. Please review these below and let me know if you have any additional question';
    const item1header = 'Do all expenses and receipts absolutely have to be in by tomorrow?';
    const item1 = `<b>${item1header}</b> Yes, all expenses and properly labeled receipts need to be added to the expense tab by the final deadline. If you have social media ad spend receipts that will not be available by the deadline please contact your Program Manager and let them know`;
    const item2header = 'Are we supposed to submit our social media metrics for the entire term tomorrow?';
    const item2 = `<b>${item2header}</b> Please submit your final social media metrics from the time you launched right up until May 8`;
    const item3header = 'Where should the final Submission items be uploaded to?';
    const item3 = `<b>${item3header}</b> All of your Final Submission items will be uploaded to their designated threads under Step 5 the Final Submission button`;
    const item4header = 'If we have questions today and tomorrow what is the best way to get a hold of you?';
    const item4 = `<b>${item4header}</b> Program Managers will be available via email, text, and quick phone calls. PMs will not be available for Zoom, Teams, or any other video chat calls.`;
    const item5header = 'What happens if we feel like we are running behind and might miss the 5:00 PM deadline?';
    const item5 = `<b>${item5header}</b> You should be making every effort to meet this deadline. However, if you are running behind or think you are going to submit late (the internet is down, portal is not working, etc) please let your Program Manager know immediately`;
    const item6header = 'Will you let us know after we submit that our Final Submission is complete and has been accepted?';
    const item6 = `<b>${item6header}</b> After all items have been submitted your Program Manager will follow up via email confirming your submission. Please be patient as it may take some time to audit everyone’s submissions`;
    const item7header = 'Should we continue to post on social media while we wait for the finalist results?';
    const item7 = `<b>${item7header}</b> Yes! You want the judges, should they view your social media page during the judging process, to see you are still active. Additionally, if you are chosen as finalists you will want to have kept your pages going so you have even larger metrics heading into the competition`;
    const item8header = 'What do we do if we have leftover budget funds?';
    const item8 = `<b>${item8header}</b> Leftover budget funds should be processed back to EdVenture Partners following the instructions in the budget guide. A screenshot showing the refund should be uploaded to the designated space in the budget manager section of the portal. Following your Final Submission Program Managers will be reviewing expenses and reconciling your budget. Your PM will contact your budget manager once they have completed this process to confirm the budget is closed out`;
    const item9header = 'When will we be notified of the finalist decision?';
    const item9 = `<b>${item9header}</b> All teams will be notified of the finalist decision on May 16. All decisions pertaining to the Final Presentation Event and competing teams are made by DHS. All decisions are final`;
    const item10header = 'If we are chosen as finalists, how do we decide who goes to the finals?';
    const item10 = `<b>${item10header}</b> Only 5 students and 1 faculty may represent the team at the Final Presentation Event. If you have more than 5 students on the team you will need to collectively decide who these team members will be. Some things to consider when choosing the 5 presenting team members:
    <ol>
    <li>Public Speaking Skills: These individuals should be charismatic and comfortable presenting to a large group with no notes or visual aids other than the slide deck</li>
    <li>Project Knowledge: These individuals should also have worked diligently on the project not only giving them the knowledge needed to present with confidence, but also to get through the 10 minute Q&A session from the panel of judges</li>
    <li>Team Dynamic: Team members should be friendly, able to work well with one another, and enjoy their time together</li>
    </ol>`;

    const closing = `
    <p><i>In the event there is difficulty deciding on the 5 students who will present, the faculty has the final say and should decide on behalf of the group.</i></p>
    <b>Items to double-check:</b> 
    <ul>
    <li>Make sure all your files are named correctly. The naming conventions for photos and creative materials can again be found in the discussion post "Photo & Video” </li>
    <li>Make sure your receipts are properly labeled</li>
    <li>Make sure your Final Report is properly labeled and submitted in the correct formatting</li>
    </ul>
    If you need assistance, please do not hesitate to contact your Program Manger. Thank you all for your continued efforts and hard work!
    `;


    return `<div id="announcement" class="w-80 m-5">
    <p>${Greeting}</p>
    <p>
    ${Opening}
    </p>
    <ul style="list-style-type: disc;">
        <li>${item1}</li>
        <li>${item2}</li>
        <li>${item3}</li>
        <li>${item4}</li>
        <li>${item5}</li>
        <li>${item6}</li>
        <li>${item7}</li>
        <li>${item8}</li>
        <li>${item9}</li>
        <li>${item10}</li>
    </ul>
    ${closing}
    <p>Best regards,</p>
    <p>Livia Hauck <br />Director of Program Operations,<br />Invent2Prevent Lead</p>
    </div>`;
}

function navbar() {
    $('#navigation').html(`
    <nav class="navbar navbar-dark bg-secondary" data-bs-theme="secondary">
        <div class="container-fluid">
            <a class="navbar-brand me-auto" href="index.html">
                <img src="${clientLogo}" alt="Logo" width="68" class="d-inline-block align-text-top rounded">
            </a>
            <button class="navbar-toggler collapsed" id="burger-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav"></ul>
            </div>
        </div>
    </nav>
    `);
}

function navItems() {
    $('.navbar-nav').html(`<li class="nav-item ms-auto"><a class="nav-link active" aria-current="page" href="/">Home</a></li>`);
    $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link active" aria-current="page" href="timeline.html">Timeline</a></li>`);
    $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link active" href="team.html">Meet the I2P Team</a></li>`);
    /* if(page !== "benefits") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Benefits of Participating</a></li>`);
    if(page !== "whois-evp") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Who is EVP</a></li>`);
    if(page !== "client") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Your Client</a></li>`);
    if(page !== "research") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Research Material</a></li>`);
    if(page !== "process") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">The Process</a></li>`);
    $('.navbar-nav').append(`
        <li class="nav-item ms-auto dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" id="navbarDropdown" aria-expanded="false">
            Reach the experts
            </a>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Project Manager #1</a></li>
                <li><a class="dropdown-item" href="#">Project Manager #2</a></li>
            </ul>
        </li>
    `); */
    burgerIcon();
}

/* Custom Menu Icon for the navigation */
function burgerIcon() {
    $('#burger-icon').html(`<img class="menu-icon rounded-circle" src="${menuIcon}">`);
}

/* Custom Foot for the portal */
function footer() {
    const year = new Date().getFullYear();
    $('#footer').html(`
    <footer class="p-3 mt-5 text-muted border-top text-center">
        <div>© <a class="link" href="https://edventurepartners.com">EdVenture Partners</a> ${year}</div>
        <div><a class="link" href="privacy_policy">Privacy Policy</a></div>
        <!-- <div>developed by <a class="link" href="https://www.github.com/chizuo">Jonathan Chua</a></div> -->
    </footer>
    `);
}

function interfaceLoading() {
    $('#interface').html(`<center><img id="floating-animation" src="${loading}"></center>`);
}

function pdf(path) {
    return `
    <div class="pdf-viewer animate__animated animate__zoomIn zoom">
        <object data="${path}" type="application/pdf" width="100%" height="100%">
            <p>This browser does not support inline PDFs. Please download the PDF to view it: <a href="${path}">Download PDF</a></p>
        </object>
    </div>
    `;
} 

function previous(view) {
    $('#return').on('click', view);
}

function returnButton() {
    return `<div class="text-center"><button id="return" class="m-2 btn btn-primary w-25"><i class="bi bi-arrow-left"></i> Return</button></div>`
};

function buttonFactory(elementID, buttonText, type='primary', elementType='div') {
    const element = elementType === "div" ? `<div class="text-center"><button id="${elementID}" class="m-5 btn btn-${type} w-25">${buttonText}</button></div>` : `<span class="text-center"><button id="${elementID}" class="m-5 btn btn-${type} w-25">${buttonText}</button></span>`
    return element;
}

function iconFactory(elementID, iconText, count) {
    let image = count === 1 ? example1 : example2;
    console.log(image);
    return `<div class="col-sm-4 g-0 m-2 animate__animated animate__lightSpeedInLeft icon-15 glow-button">
        <div id="${elementID}" class="square-container-normal">
            <img class="fill icon-15 dark-33" src="${image}">
            <div class="overlay-text text-center display-4">${iconText}</div>
        </div>
    </div>`
}

function helpElement(direction = 'left') {
    let animation = direction == 'left' ? 'animate__backInLeft' : 'animate__backInRight';
    return `<div id="help" class="col-sm-4 g-0 m-2 animate__animated ${animation} icon-15 glow-button ${direction}">
                <div class="square-container-normal">
                    <img class="fill icon-15" src="${Help}"> 
                </div>
            </div>`;
}

function init(participant, main, location) {
    if(participant === null) {
        window.location.href = 'login';
    } else if(participant.tracked.step1["Watch kickoff video"] === 0 && participant.type == "student" && location !== "homepage") {
        window.location.href = '/';
    } else {
        initApp();
        main();
    }
}

export {
    $,
    announcement,
    navbar,
    navItems,
    footer,
    menuIcon as evpIcon,
    domain,
    loading,
    helpElement as Help,
    iconFactory,
    init,
    initApp,
    interfaceLoading,
    description,
    pdf,
    previous,
    resource,
    returnButton,
    buttonFactory,
};